$blue: #0080e5;
$green: #00aabf;
$success: #74e1ba;
$danger: #ff4961;
$transition: all ease 0.5s;
$transitionSlow: all ease 2s;

$mainSolidBg: #5d378d;
$secondarySolidBg: #926a19;
$textColor: #c3852b;
